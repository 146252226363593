import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import favicon from '../../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../../images/undersidor/skanningsprogramvara.svg";
import fakturadata_img from '../../images/undersidor/fakturadata.png';

const KONTAKT_BOT = "botxWuWV";
const DEMO_BOT = "WfgujSpL";

function Fakturakontroll() {
    const [kontakt_1, set_kontakt_1] = useState(false);
    const [demo_1, set_demo_1] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Fakturakontroll | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Kontrollera fakturadata effektivare" />
                <meta name="keywords" content="CrossState eVoyce" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Kontrollera fakturadata effektivare" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/leverantorsfakturor/fakturakontroll" />
                <link rel="cannonical" href="https://www.crediflow.se/leverantorsfakturor/fakturakontroll" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Kontrollera fakturadata och lär systemet att bli effektivare</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        CrossState eVoyce är ett självlärande system, vilket 
                                        gör att du inte behöver lägga tid på att skapa mallar 
                                        för hur fakturor ska tolkas samt att tolkningen blir 
                                        säkrare allt eftersom fakturor hanteras.
                                    </p>
                                    <button onClick={() => set_kontakt_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {kontakt_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={KONTAKT_BOT} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:w-1/3 lg:w-1/2 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <Link to="/leverantorsfakturor" className="flex items-center text-blue-800 hover:text-blue-600">leverantörsfakturor</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">fakturakontroll</span>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 pt-12 pb-32" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <p className="leading-relaxed text-lg text-gray-600">
                                I början kommer systemet be dig kontrollera fält 
                                som applikationen är osäker på eller manuellt lägga 
                                till information om osäkerheten gör att den inte 
                                vill föreslå ett värde. När kontrollen är gjord 
                                skapas kunskap om hur fakturan ska hantera nästa 
                                gång. I takt med att kunskap byggs och används 
                                blir tjänsten säkrare och kontrollen minskar 
                                till att du bara ger ett ok på fakturan med en knapptryckning.
                                <br/><br/>
                                Kontrollen gör att den data som levereras till ditt 
                                nästkommande system alltid är korrekt samt att 
                                applikationen lär sig, blir bättre och sparar dig tid.
                                <br/><br/>
                                Vill du göra kontrollen själv får du en inloggning 
                                och utbildning i vårt system annars låter du oss sköta arbetet.
                                <br/><br/>
                                Vi kan även dela upp ditt flöde så att vi tillexempel 
                                hanterar dina omkostnadsfakturor medan du kontrollerar 
                                varufakturor med ordrar och artiklar som ska 
                                kopplas mot inköp eller tvärtom.
                                <br/><br/>
                                Vi är flexibla enligt hur du vill ha det.
                            </p>
                            <button onClick={() => set_demo_1(true)} className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Boka Demo</button>
                            
                            {demo_1 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={DEMO_BOT} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                
                        <div className="w-full sm:w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={fakturadata_img} alt="kontrollera fakturadata" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Fakturakontroll;